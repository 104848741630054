import Swiper from 'swiper';
import Navigation from 'swiper/modules/navigation.mjs';
import Pagination from 'swiper/modules/pagination.mjs';

export class Slider {
    /**
     * @param {Element} element
     */
    constructor(element) {
        this.container = element;
        this.swiperContainer = this.container.querySelector('.swiper');
        this.prevButton = this.container.querySelector('.tire-lait-product-slider-prev');
        this.nextButton = this.container.querySelector('.tire-lait-product-slider-next');
        this.pagination = this.container.querySelector('.tire-lait-product-slider-pagination');

        this.swiper = new Swiper(this.swiperContainer, {
            modules: [Navigation, Pagination],
            navigation: {
                prevEl: this.prevButton,
                nextEl: this.nextButton,
            },
            pagination: {
                el: this.pagination,
            },
        });
    }
}
