import "./loader.scss"

export default class Loader {

    constructor($container, options) {

        this.settings = $.extend({}, {
            refresh_interval: 50,
            elements_count: 20,
            autostart: true,
            autostop: true,
            delay: 500,
            fadeout_duration: 200
        }, options);
        this.$container = $container;
        this.register = [];
        this.startTimeouts = [];
        this.runningTasks = [];
        this.started = false;
        this.animationTimeout = null;

    }

    get $svg() {
      if (this._loadSvgPromise === undefined) {
        this._loadSvgPromise = (async () => $((await import('!raw-loader!./loader.cleaned.svg')).default))()
      }
      return this._loadSvgPromise
    }

    get $overlay() {
      if (this._loadOverlayPromise === undefined) {
        this._loadOverlayPromise = (async () => {
          let $overlay = $('<div/>', { class: 'overlay' })
          let $svg = (await this.$svg)
          $svg.appendTo($overlay)
          return $overlay
        })()
      }
      return this._loadOverlayPromise
    }

    configure(options) {
        this.settings = $.extend({}, this.settings, options);
    }

    startTask(id) {

        if (id === undefined) {
            id = new Date().getTime();
        }

        this.runningTasks.push(id);

        if (this.runningTasks.length === 1 && this.settings.autostart) {
            this.start();
        }

        return id;

    }

    stopTask(id) {
        let index = this.runningTasks.indexOf(id);

        if (index > -1) {
            this.runningTasks.splice(index, 1);
        }

        if (this.runningTasks.length === 0 && this.settings.autostop) {
            this.stop();
            return true;
        }

        return false;

    }

    isStarted() {
        return this.started;
    }

    async updatePosition() {

        let windowTop = $(window).scrollTop();
        let windowBottom = windowTop + $(window).height();
        let containerPosition = this.$container.offset();
        let containerHeight = this.$container.height();
        let containerBottom = containerPosition.top + containerHeight;
        let containerTop = containerPosition.top;

        let svgHeight = (await this.$svg).height();
        let svgVerticalRange = Math.min(
                containerHeight,
                Math.max(
                    (await this.$svg).height() / 2,
                    Math.min(windowBottom, containerBottom) - Math.max(windowTop, containerTop)
                )
            );

        let svgVerticalOffset = Math.min(
                Math.max(0, windowTop - containerTop),
                Math.max(0, containerHeight - svgHeight)
            );

        (await this.$svg).css({
            top:  svgVerticalOffset + svgVerticalRange / 2 - svgHeight / 2,
            left: this.$container.width() / 2 - (await this.$svg).width() / 2
        });

    }

    start() {
        if (!this.$container || this.$container.length === 0) {
          return
        }
        this.startDelayedTimeout = setTimeout(async () => {
            if (this.$container.css('position') == 'static') {
                this.$container.css('position', 'relative');
            }
            (await this.$overlay).appendTo(this.$container);
            (await this.$overlay).show();
            this.updatePosition();

            let circles = (await this.$overlay).find('circle');

            for (let x=0 ; x < this.settings.elements_count ; x++) {

                let rCircle = circles.get(Math.floor((Math.random() * circles.length)));

                this.startTimeouts.push(setTimeout(
                    this.registerAnimation.bind(this,
                        $(rCircle), this.rand(100, 100), this.rand(5, 10), this.rand(50, 20), this.rand(4000, 2000)),
                    this.rand(0, 2000)
                ));
            }
        }, this.settings.delay);
        this.started = true;

    }

    registerAnimation($dot, animation_length, amplitude, periode, duration, duration_gap) {

        this.register.push({
            id: this.register.length,
            $element: $dot,
            animation_length: animation_length,
            amplitude: amplitude,
            periode: periode,
            duration: duration,
            step: 0
        });


        if (this.animationTimeout === null) {
            this.animate();
        }

    }

    animate() {

        for (let config of this.register) {

            if (config.cache == undefined) {
                config.cache = {
                    x_increment: config.animation_length / (config.duration / this.settings.refresh_interval),
                    phase: 2 * Math.PI * (config.animation_length / config.periode) / (config.duration / this.settings.refresh_interval)
                };
            }

            let x = config.step * config.cache.x_increment;
            let y = (config.amplitude / 2) * Math.sin(config.step * config.cache.phase);

            config.$element.attr("transform", "translate(" + x + ", " + y + ")");
            config.$element.css("opacity", 1 - config.step / (config.duration / this.settings.refresh_interval));

            config.step = (x < config.animation_length) ? (config.step + 1) : 0;

        }

        this.updatePosition();

        this.animationTimeout = setTimeout(this.animate.bind(this), this.settings.refresh_interval);

    }

    rand(fix, range) {
        return fix + Math.floor(Math.random() * range);
    }

    async stop() {
        clearTimeout(this.startDelayedTimeout);
        (await this.$overlay).fadeOut(this.settings.fadeout_duration, async () => {
            if (this.animationTimeout !== undefined && this.animationTimeout !== null) {
                clearTimeout(this.animationTimeout);
                this.animationTimeout = null;
            }

            this.startTimeouts.map(to => clearTimeout);
            this.startTimeouts = [];
            this.animationTimeout = null;

            for (let config of this.register) {
                config.$element.attr("transform", "translate(0, 0)");
                config.$element.css("opacity", 1);
            }
            this.register = [];

            (await this.$overlay).detach();
            this.started = false;
        });

    }

}
