import EventManager from "../eventManager.js";

export default class FormManager extends EventManager {

    constructor(options) {
        super();

        this.settings = $.extend({}, {
        }, options);


        this.error = {

            show: function($input, message, bubble) {

                bubble = bubble !== undefined && bubble;

                var $msgContainer = null;

                if (bubble) {
                    $msgContainer = $input.closest('.form-container').children('.error-message').first();
                } else {
                    $msgContainer = $input.next('.error-message').first();
                }


                if (! $msgContainer.length) {
                    $msgContainer = $('<p class="error-message"></p>');
                    if (bubble) {
                        $input.closest('.form-container').prepend($msgContainer);
                    } else {
                        $msgContainer.insertAfter($input);
                    }
                }

                $input.closest('.form-container').toggleClass('error', true);
                $input.parent().toggleClass('error', true);
                $input.toggleClass('error', true);

                $msgContainer.html(message);

            },

            remove ($input, bubble) {

                bubble = bubble !== undefined && bubble;

                $input.toggleClass('error', false);
                $input.next('.error-message').remove();

                if ( $input.parent().find('.error').length == 0 ) {
                    $input.parent().toggleClass('.error', false);
                }

                if (bubble) {
                    $input.closest('.form-container').children('.error-message').remove;
                    if ($input.closest('.form-container').find('.error-message').length == 0) {
                        $input.closest('.form-container').toggleClass('error', false);
                    }
                }

            }

        }
    }
}
