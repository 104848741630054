import EventManager from "../eventManager.js"
import Config from "../config.js"
import Modernizr from "modernizr"

export default class ViewportManager extends EventManager {

  get MOBILE_VIEWPORT() { return 0 }
  get DESKTOP_VIEWPORT() { return 1 }

  constructor(settings) {
    super()
    this.viewportMode = null
    $(window).on('resize', this.onResize.bind(this))
    $(this.onResize())
  }

  onResize (event) {
    this.on('resize', event) 
    if (Modernizr.mq(`(min-width: ${Config.desktopThresholdViewportWidth})`)) {
      // desktop
      if (this.viewportMode !== this.DESKTOP_VIEWPORT) {
        this.on('viewport_change', this.viewportMode = this.DESKTOP_VIEWPORT)
      }
    } else {
      // mobile
      if (this.viewportMode !== this.MOBILE_VIEWPORT) {
        this.on('viewport_change', this.viewportMode = this.MOBILE_VIEWPORT)
      }
    }
  }

}
