// new js architecture
import 'babel-polyfill';
import 'slick-carousel';
import 'slick-carousel/slick/slick.scss';
import 'slick-carousel/slick/slick-theme.scss';
import App from './app.js';
import Analytics from '../modules/google/Analytics'

'use strict';
require("font-awesome-sass-loader");
require("../js/jquery.backtop.js");

document.addEventListener("DOMContentLoaded", Analytics.init());

// Fix hover state on touchscreens
(function(document) {
  let s = {touchend:function(){}};
  for (let i in s) {
    document.addEventListener(i,s)
  }
})(document);

window.app = new App();
window.onerror = function(message, source, lineno, colno, error) {
  if (error instanceof Error) {
    error = {
      message: error.message,
      stack: error.stack
    }
  }
  app.getLogsManagerInstance().error({
    message: message,
    error: error,
    source: { 
      source: source,
      lineNumber: lineno,
      colNumber: colno
    }
  })
  return false;
}
window.addEventListener("unhandledrejection", function(promiseRejectionEvent) { 
  app.getLogsManagerInstance().error({
    message: promiseRejectionEvent.reason && promiseRejectionEvent.reason.message
      ? promiseRejectionEvent.reason.message
      : 'Empty reason message',
    stack: promiseRejectionEvent.reason.stack
  })
  return false;
});


// bindings for old popup calls
$(() => {window.popup = app.getPopupManagerInstance()})

window.lastPopupPreCloseToDo = null
window.lastPopupPostCloseToDo = null

window.closePopUp = function () {
    window.popup.close(window.lastPopupPostCloseToDo, null, window.lastPopupPreCloseToDo);
}

window.openPopUp = function (id_popup, titre, height, width, withOverlay, closing_timeout, bts, fn, fn_args) {

    var options = {};

    if (typeof width != 'undefined') {
        options.width = width;
    }

    if (typeof withOverlay != 'undefined') {
        options.withOverlay = withOverlay;
    }

    if (typeof closing_timeout != 'undefined') {
        options.closingTimeout = closing_timeout;
    }

    window.lastPopupPreCloseToDo =  function () {
      $('body').append($("#" + id_popup));
      $("#" + id_popup).css("display", "none");
    }

    window.lastPopupPostCloseToDo =  function () {
      if (fn && (typeof fn === "function")) {
          fn(fn_args);
      }
    }

    window.popup.show($("#" + id_popup), titre, options, window.lastPopupPostCloseToDo, window.lastPopupPreCloseToDo);

    $("#" + id_popup).css("display", "block");

}

window.popupNewsletter = function (title, text, options) {

    // testAb on NL popup
    app.getTestsManagerInstance().then(testManager => {
      if (! testManager.getTest('testABNLPopup')) {
          return;
      }
    })

    var $content = $('<div/>');
    if ($("form#newsletter").length) {
        $content.append($('<p/>').html(text)).append($("form#newsletter").clone().attr('id', 'newsletter_form_popup'));
        $content.find('input[type=submit]').toggleClass('button', true);
        app.getPopupManagerInstance().show($content, title, options);
    }

};
// end bindings for old popup calls


// import "jquery-ui/themes/base/base.css";
// import "jquery-ui/themes/base/theme.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";





//Fonction init


window.ScrollHandler = require('../modules/scroll/scroll.js').default;
// Add / Update a key-value pair in the URL query parameters
window.updateUrlParameter = function (uri, key, value) {
    // remove the hash part before operating on the uri
    var i = uri.indexOf('#');
    var hash = i === -1 ? '' : uri.substr(i);
    uri = i === -1 ? uri : uri.substr(0, i);

    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(re)) {
        uri = uri.replace(re, '$1' + key + "=" + value + '$2');
    } else {
        uri = uri + separator + key + "=" + value;
    }
    return uri + hash;  // finally append the hash as well
}

// Retrieve a key-value pair in the URL query parameters
window.getUrlParameter = function (uri, key) {

    var re = new RegExp("([?&])" + key + "=(.*)?(&|$|#)", "i");
    if (uri.match(re)) {
        var matches = re.exec(uri);
        return matches !== null ? matches[2] : null;
    } else {
        return null;
    }

}

window.createLoader = function (id) {
  let size = 20, radius = 3, duration = 1000,
    maxOpacity = 0.6, minOpacity = 0.15;

  return $('<svg id="' + id + '" width="' + (size * 3.5) + '" height="' + size + '">' +
    '<rect width="' + size + '" height="' + size + '" x="0" y="0" rx="' + radius + '" ry="' + radius + '" fill-opacity="' + maxOpacity + '">' +
    '<animate attributeName="opacity" values="1;' + minOpacity + ';1" dur="' + duration + 'ms" repeatCount="indefinite"/>' +
    '</rect>' +
    '<rect width="' + size + '" height="' + size + '" x="' + (size * 1.25) + '" y="0" rx="' + radius + '" ry="' + radius + '" fill-opacity="' + maxOpacity + '">' +
    '<animate attributeName="opacity" values="1;' + minOpacity + ';1" dur="' + duration + 'ms" begin="' + (duration / 4) + 'ms" repeatCount="indefinite"/>' +
    '</rect>' +
    '<rect width="' + size + '" height="' + size + '" x="' + (size * 2.5) + '" y="0" rx="' + radius + '" ry="' + radius + '" fill-opacity="' + maxOpacity + '">' +
    '<animate attributeName="opacity" values="1;' + minOpacity + ';1" dur="' + duration + 'ms" begin="' + (duration / 2) + 'ms" repeatCount="indefinite"/>' +
    '</rect>' +
    '</svg>');
}

window.sidentifier = function () {
  window.app.askForLogin()
}

/**
 *
 * @returns {undefined}
 */
window.updateQuantitePanier = function (...args) {
  window.app.getBasketManagerInstance().then(basketManager => {
    basketManager.refreshSummary.apply(basketManager, args)
  })
}


//Changement du fond avec une image plus lourde mais une fois que le dom est charg�
window.changeBackground = function () {
// 	var d = new Date();
    /*var tmp_src = '/images/interface/responsive/background/bg.jpg';
     if(d.getTime() > 1452063600000 && d.getTime() < 1455663540000){
     tmp_src = '/images/interface/responsive/background/bg_soldes.jpg';
     }
     (new Image).src = tmp_src;
     $('body').css('background-image', 'url(' + tmp_src + ')');*/
//$("body").css("background-image", "url()");

}

window.addToCart = function (...args) {
  window.app.getBasketManagerInstance().then(basketManager => {
    basketManager.addProduct.apply(basketManager, args)
  })
}



var recherche_timer;
window.onkey_recherche = function () {
    $("#recherche_update").css("display", 'none');
    clearTimeout(recherche_timer);
    recherche_timer = setTimeout(do_recherche, 500);
}

window.do_recherche = function () {
    var value = $("#recherche").val();
    if (value.length >= 2) {
        $.get('core/recherche/autocomplete.php', {
            recherche: value
        },
                function (data) {
                    if (data.length > 0) {
                        $("#recherche_update").html(data).css("display", "block");
                        $("#recherche").addClass('activated');
                        $(window).click(function () {
                            $("#recherche_update").css("display", 'none');
                            $("#recherche").removeClass('activated');
                            $(window).click(null);
                        });
                    }
                });
    }
}
/**
 *
 * @param {type} email
 * @returns {Boolean}
 */
window.verif_mail = function (email) {
    var verif = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9._-]{1,}[.][a-zA-Z]{2,4}$/;
    if (verif.exec(email) === null) {
        return false;
    } else {
        return true;
    }
}


window.alerte_stock = function (id_aref, titre) {
    $.post('core/alerte_client.php', {
        id_art_ref: id_aref
    }, function (data) {
        popup.show(data, titre !== undefined ? titre : "Indisponible actuellement ... Je reviens vite (promis) !", {
            width: 640,
            withOverlay: true
        });
    });
}

window.valide_alerte_stock = function (id_art_ref, _mail, _prenom) {
    $.post('core/ajax/ajax_alertdispo.php', {
        id_art_ref: id_art_ref,
        email: _mail,
        prenom: _prenom
    }, function (data) {
        popup.info(data, {
            title: "Indisponible actuellement ... Je reviens vite (promis) !",
            width: 640
        });

    });
}

window.pass_perdu = function () {
    return openPopUp("passperdu_window", "Mot de passe perdu ?", null, 600, true);
}

window.pass_perdu_validate = function (email) {
    if (verif_mail(email)) {
        app.evaluateRecaptchaScore().then(reCaptchaToken => 
            $.post('core/ajax/ajax_passperdu.php', {
                email_in: email,
                reCaptchaToken
            }, function (data) {
                closePopUp();
                popup.info(data, {
                    title: "Mot de passe perdu ?",
                    width: 640
                });
            })
        )
        
        return true;
    } else {
        closePopUp();
        popup.info("Votre adresse e-mail est incorrecte. Merci de corriger.", {type: 'error'}, window.pass_perdu);
        return false;
    }
}


window.getVilles = function (form) {
    var id = $(form).prop("id");
    var cpos = null;
    var tmp_id = id;
    if (id !== undefined) {
        id = "#" + id + " ";
        cpos = $('' + id + "#cp").prop("value");
    } else {
        id = "";
        cpos = $("#cp").prop("value");
    }

    if (cpos.length === 5 && $('' + id + "#pays").prop("value") === "FRA") {
        $.post('core/ajax/ajax_cp_ville.php', {
            cp: cpos,
            form: tmp_id
        },
                function (data) {
                    $('' + id + '#listing_ville').fadeIn();
                    $('' + id + '#listing_ville').html(data);
                    //$('' + id + "#detecte_ville").focus()
                }
        );
    }
}

window.setVille = function (val, form) {
    var id = $(form).attr("id");
    if (id != undefined) {
        id = "#" + id + " ";
    } else {
        id = "";
    }
    $('' + id + "#ville").val(val);
    $('' + id + "#ville").focus();
}

window.toggleFilters = function () {
    $("#filtres_form .filtres_overlay").toggle();
}

window.chooseToList = function (...args) {
  window.app.getListManagerInstance().then(listManager => {
    listManager.chooseToList.apply(listManager, args)
  })
}

window.addToList = function (...args) {
  window.app.getListManagerInstance().then(listManager => {
    listManager.addToList.apply(listManager, args)
  })
}

window.startLoading = function () {
    openPopUp("loading", "Chargement ...", null, 200);
    $("#closeModal").hide();
}

window.stopLoading = function () {
    closePopUp();
    $("#closeModal").show();
}

window.initFolders = app.handleFolders;

window.clickMea = function (idmea, iduniv, pos) {
    $.get('core/ajax/ajax_stat_mea.php', {
        id_mea: idmea,
        id_univers: iduniv
    });
    gtag('event', 'click', { 'event_category': 'Mea', 'event_label': 'Mea Pos ' + pos + ' Univers ' + iduniv });
}

window.showFormError = app.getFormManagerInstance().error.show;

document.addEventListener('DOMContentLoaded', () => {
    window.app.tireLait();
});
