import PopupManager from "./popupManager.js";
import EventManager from "../eventManager.js";
import "./advert.scss";

export default class Advert extends PopupManager {

    constructor(options) {
        options = $.extend({}, {
            extraClassNames: [],
            persistence: false
          }, options)
        options.extraClassNames.push('advert');
        super(options);
    }

    show(content, title, options, ...rest) {
      if (options.position === 'top') {
        $('body').css({paddingTop: '27px'})
      }
      super.show(content, title, options, ...rest)
    }

    close(force) {
        if (force === true || !this.settings.persistence) {
          $('body').css({paddingTop: '0px'})
          super.close()
        } else {
          let [ postClose, closeStyle, preClose, ...args ] = arguments
          super.on(this.settings.persistence, ...args)
        }
    }

}
