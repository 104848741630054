import PopupManager from '../modules/popup/popupManager.js';
import Advert from '../modules/popup/advert.js';
import Loader from '../modules/loader/loader.js';
import FormManager from '../modules/forms/formManager.js';
import FoldersManager from '../modules/folders/foldersManager.js';
import MenuManager from '../modules/menu/menuManager.js';
import EventManager from '../modules/eventManager.js';
import ToolbarManager from '../modules/menu/toolbarManager.js';
import ProductManager from '../modules/product/productManager.js';
import ViewportManager from '../modules/viewport/viewportManager.js';
import LogsManager from '../modules/logger/logsManager.js';
import {Accordion, Popin, Slider} from '../modules/tire-lait';
import {PopinManager} from '../modules/tire-lait/popin-manager';

export default class App extends EventManager {

    constructor(options) {
        super();

        this.getViewportManagerInstance();
        let GoogleAnalyticsEvent = require('../modules/tracking/googleAnalyticsEvent.js').default;
        this.gaEventAdvert = new GoogleAnalyticsEvent({category: 'Advert', transport: 'beacon'});

        this.loadReCaptcha = new Promise((resolve, reject) => grecaptcha.ready(resolve));

        this.evaluateRecaptchaScore = () => this.loadReCaptcha.then(() => grecaptcha.execute('6LcyebQUAAAAAI_6oDQDALGbz72H0jLTBBulK2CD'));

        $(() => {
            if (!localStorage || !(localStorage.getItem) || !localStorage.getItem('has_seen_shipping_offer')) {
                this.advert = new Advert({persistence: false, extraClassNames: ['no-content']});
                const handleAdvert = () => {
//         if (this.advert && !this.advert.isOpen()) {
                    //         this.advert.show(
                    //         '',
                    //       `<span class="show_promo">Livraison offerte dès 29&euro; en relais colis pour toute 1ère commande avec le code BIENVENUE20 </span> <span class="show_promo_on_mobil_desktop">  Code BIENVENUE20 : 1ère livraison offerte des 29&euro;</span>  <sup class="show_details"> sur les produits vendus et expédiés par Bébé au Naturel</sup>`,
                    //     { width: '100%', position: 'top' })
                    //   }
                };
                // Ne pas oublier de retirer le padding dans generalCSS

                this.advert.addListener('close', () => {
                    if (localStorage || localStorage.setItem) {
                        localStorage.setItem('has_seen_shipping_offer', true);
                    }
                });
                this.getViewportManagerInstance().addListener('viewport_change', handleAdvert);
                handleAdvert();

            }
            // end advert init

            $('#filtres_form .filtres_overlay').click(function () {
                toggleFilters();
            });

            $('#filtres_form .filtres').click(function (event) {
                event.stopPropagation();
            });

            // Top toolbar handling
            this.toolbar = this.handleMainToolbar($('body > .toolbar'));

            // Menu handling
            this.menu = this.handleMenu($('#main-menu'));

            // Products handling
            this.getProductManagerInstance().handle($('.product-container'));

            // $("img.lazy").show().lazyload({ effect: "fadeIn" })

            // Products list lazy-load
            if ($('body .articles_liste').length > 0) {
                let loading = false;

                // Handle events that can modify bottom list position relatively to viewport bottom
                $(window).on('scroll resize', () => {
                    let $link_next = $('head link[rel=next]');

                    // Do nothing if there is no more content
                    if ($link_next.length > 0 && $('body .articles_liste').is(':visible')) {

                        let window_bottom = $(window).scrollTop() + $(window).height();
                        let container = $('body .articles_liste').closest('.inner_rubrique');
                        let container_bottom = container.offset().top + container.innerHeight();
                        let next_page = $link_next.attr('href');
                        let line_height = $('body .articles_liste > li.produit_rubrique').first().outerHeight();
                        let min_gap = line_height * 2; // gap is fixed at 2 products line tall

                        // Do nothing if there is already a request on air or if the bottom is not close enough to the viewport bottom
                        if (!loading && (container_bottom - window_bottom) < min_gap) {

                            let jqxhr = $.ajax({
                                url: next_page,
                                beforeSend: () => {
                                    // Display a loader indicator and flag the pending request
                                    loading = true;
                                    $('body .articles_liste')
                                        .append($('<li class="category_loader"/>')
                                            .append(window.createLoader('rubrique_loader')));
                                },
                            })
                                .done(html => {
                                    let $response = $('<div>' + html + '</div>');
                                    let $products = $response.find('.articles_liste > li');

                                    // Received list contains products to be appended to the page
                                    if ($products.length > 0) {

                                        // $products.find("img.lazy").show().lazyload({effect: "fadeIn"})

                                        // remove category title if it is already present in page
                                        if ($products.first().has('a[name] h2')) {
                                            let name = $products.first().find('a[name]').attr('name');
                                            if (name !== null && $('body .articles_liste').find('a[name=\'' + name + '\']').length > 0) {
                                                [].shift.call($products);
                                            }
                                        }

                                        $('#rubrique_loader').closest('.category_loader').remove();

                                        $products.appendTo($('body .articles_liste'));
                                        this.getProductManagerInstance().handle($products);
                                        // $('body .articles_liste').append($products)

                                        // update history stack in order to handle back request
                                        // update page parameters

                                        let startPage = 1;
                                        let endPage = 1;
                                        let currentRange = window.getUrlParameter(window.location.href, 'page');
                                        if (currentRange === null) {
                                            startPage = 1;
                                        } else {
                                            startPage = currentRange.split('-')[0];
                                        }
                                        endPage = window.getUrlParameter(next_page, 'page');
                                        let next_range = startPage + '-' + endPage;
                                        let next_href = window.updateUrlParameter(window.location.href, 'page', next_range);

                                        history.replaceState({}, document.title, next_href);

                                        // Retrieve the next content page url and update link in document's head
                                        let nextPageUrl = $response.find('.articles_liste').attr('data-nextPageUrl');

                                        if (nextPageUrl) {
                                            $link_next.attr('href', nextPageUrl);
                                        } else {
                                            $link_next.remove();
                                        }

                                    } else {
                                        $link_next.remove();
                                    }
                                })
                                .fail(function () {
                                })
                                .always(function () {
                                    // At end, remove loading flag and loader indicator
                                    loading = false;
                                    $('#rubrique_loader').closest('.category_loader').remove();
                                    $(window).scroll();
                                });
                        }

                    }
                });
            }

            // Bottom arrow init
            $('#backTop').backTop({
                'position': 800,
                'speed': 500,
                'color': 'black',
            });

            this.getBasketManagerInstance().then(basketManager => {
                basketManager.refreshSummary.apply(basketManager);
            });

            // Responsive logo handling
            $(window).scroll(function () {
                if ($('#main_logo').is(':visible')) {
                    $('#mobile_logo').fadeOut();
                } else {
                    $('#mobile_logo').fadeIn().css('display', 'inline-block');
                }
            });

            // Miscellaneous dynamic widgets handling
            $('.stacked-content').find('> h4').click(function () {
                $(this).next('ul').toggleClass('hidden');
            });

            $('.stacked-content').find('> ul').toggleClass('hidden', true);

            $('.video-js').each((i, e) => {
                import('../modules/video/video.js').then(ClassDefinition => ClassDefinition.default)
                    .then(VideoPlayer => new VideoPlayer(null, {autoStart: false}).init(e));
            });

            $('.mea.caroussel-full').slick({
                autoplay: true,
                autoplaySpeed: 5000,
                speed: 900,
                arrows: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
            });

            $('.brands.caroussel-full').slick({
                autoplay: true,
                autoplaySpeed: 5000,
                speed: 900,
                arrows: false,
                slidesToShow: 10,
                slidesToScroll: 3,
                infinite: true,
                dots: false,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 8,
                            slidesToScroll: 2,
                        },
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 6,
                            slidesToScroll: 2,
                        },
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                        },
                    },
                ],
            });

            if ($('body .module.compte, body form[name=inscription], body form[name=deja_client]').length > 0) {
                this.getAccountManagerInstance().then(am => am.handle($('body .module.compte, body form[name=inscription], body form[name=deja_client]')));
            }

            $('body .search-box input[type=text][name=query]').focus(
                async (event) => {
                    let $box = $(event.delegateTarget).closest('.search-box');
                    $box.addClass('open');

                    if (!$box.data('handled')) {
                        $box.data('handled', true);
                        let searchManager = await this.getSearchManagerInstance();
                        searchManager.handle($box);
                    }
                },
            );

            $('body .drop-button').each(async (i, button) =>
                (await this.getDropButtonManagerInstance()).handle($(button)));

            $('body .filters-form').each(async (i, form) =>
                (await this.getFiltersManagerInstance()).handle($(form)));

            this.getFiltersManagerInstance().then(fm => fm.handleSelectionChips($('body .filter-chip')));

            $(document).on('click touchstart touchmove', event => {
                $('body .search-box').each(async (i, searchBox) => {
                    let $box = $(searchBox);
                    if ($box.data('handled')) {
                        let searchManager = await this.getSearchManagerInstance();
                        if (!$.contains(searchBox, event.target) && !searchManager.hasFocus($box)) {
                            $box.removeClass('open');
                        }
                    }
                });
            });

        });
    }

    getDropButtonManagerInstance(options) {
        if (this.dropManager === undefined) {
            this.dropManager = import('../modules/drop-button/dropButtonManager.js')
                .then(ClassDefinition => new ClassDefinition.default(options));
        }
        return this.dropManager;
    }

    getFiltersManagerInstance(options) {
        if (this.filtersManager === undefined) {
            this.filtersManager = import('../modules/drop-button/filtersManager.js')
                .then(ClassDefinition => new ClassDefinition.default(options));
        }
        return this.filtersManager;
    }

    getListManagerInstance(options) {
        if (this.listManager === undefined) {
            this.listManager = import('../modules/list/listManager.js').then(ClassDefinition => new ClassDefinition.default(options));
        }
        return this.listManager;
    }

    getSearchManagerInstance(options) {
        if (this.searchManager === undefined) {
            this.searchManager = import('../modules/search/searchManager.js').then(ClassDefinition => new ClassDefinition.default(options));
        }
        return this.searchManager;
    }

    getBasketManagerInstance(options) {
        if (this.basketManager === undefined) {
            this.basketManager = import('../modules/basket/basketManager.js').then(ClassDefinition => new ClassDefinition.default(options));
        }
        return this.basketManager;
    }

    getReviewsManagerInstance(options) {
        if (this.reviewsManager === undefined) {
            this.reviewsManager = import('../modules/reviews/reviewsManager.js').then(ClassDefinition => new ClassDefinition.default(options));
        }
        return this.reviewsManager;
    }

    getAccountManagerInstance(target, options) {
        if (this.accountManager === undefined) {
            this.accountManager = import('../modules/account/accountManager.js').then(ClassDefinition => new ClassDefinition.default(target, options));
        }
        return this.accountManager;
    }

    getPopupManagerInstance() {
        if (this.popupManager === undefined) {
            this.popupManager = new PopupManager();
            this.popupManager.addListener('contentLoaded', (popup, $content) => this.handleFolders($content.find('foldered-content')));
        }
        return this.popupManager;
    }

    getNotificationManagerInstance() {
        if (this.notificationManager === undefined) {
            this.notificationManager = new PopupManager();
        }
        return this.notificationManager;
    }

    getLogsManagerInstance() {
        if (this.logsManager === undefined) {
            this.logsManager = new LogsManager();
        }
        return this.logsManager;
    }

    getProductManagerInstance() {
        if (this.productManager === undefined) {
            this.productManager = new ProductManager();
        }
        return this.productManager;
    }

    createLoaderInstance($element, options) {
        return new Loader($element, options);
    }

    getTestsManagerInstance() {
        if (this.testsManager === undefined) {
            this.testsManager = import('../modules/testing/testsManager.js').then(ClassDefinition => new ClassDefinition.default());
        }
        return this.testsManager;
    }

    getFormManagerInstance() {
        if (this.formManager === undefined) {
            this.formManager = new FormManager();
        }
        return this.formManager;
    }

    getViewportManagerInstance() {
        if (this.viewportManager === undefined) {
            this.viewportManager = new ViewportManager();
        }
        return this.viewportManager;
    }

    getTrackerManagerInstance(trackerClassName) {
        if (!this.tracker) this.tracker = new Map();
        if (!this.tracker.has(trackerClassName)) {
            this.tracker.set(trackerClassName, import('../modules/tracking/' + trackerClassName + '.js').then(ClassDefinition => new ClassDefinition.default()));
        }
        return this.tracker.get(trackerClassName);
    }

    handleFolders($folderedContent) {
        return FoldersManager.handle($folderedContent);
    }

    handleMenu($menu) {
        return MenuManager.handle($menu);
    }

    handleMainToolbar($toolbar) {
        return ToolbarManager.handle($toolbar);
    }

    askForLogin() {
        let $popupContent = $('<div/>', {style: 'min-height: 200px'});

        this.getPopupManagerInstance().show($popupContent, 'M\'identifier ou m\'inscrire', {withOverlay: true});
        let loader = this.createLoaderInstance($popupContent);
        let task = loader.startTask();

        $.get('core/ajax/ajax_minilogin.php')
            .done(function (html) {
                $popupContent.html(html);
                window.app.getAccountManagerInstance().then(am => am.handle($popupContent.find('form[name=inscription], form[name=deja_client]')));
            })
            .always(() => loader.stopTask(task));

        return false;
    }

    isUserLoggedIn() {
        return window._appUserIsLoggedIn;
    }

    isNewSession() {
        return window._appIsNewSession;
    }

    getSessionVar(key) {
        return window._appSessionVars && window._appSessionVars[key];
    }

    tireLait() {
        document.querySelectorAll('.tire-lait-accordion').forEach(element => new Accordion(element));
        document.querySelectorAll('.tire-lait-product-slider').forEach(element => new Slider(element));
        new PopinManager(
            Array.from(document.querySelectorAll('.pop-in-container')).map(element => new Popin(element))
        );
    }
}
