import EventManager from "../eventManager.js";

export default class ToolbarManager extends EventManager {

  constructor($target, options) {
    super()
    this.settings = $.extend({}, {}, options)
    this.$container = $target

    // CREATION DE L'EVENEMENT - viewportChange
    $(document).ready(function () {
      if (navigator.userAgent.includes("Mobile") || window.innerWidth <= 800) {
        if (window.innerWidth > 800) {
          window.viewportType = 'TABLET';
        }else {
          window.viewportType = 'MOBILE';
        }
      }else {
        window.viewportType = 'DESKTOP';
      }

      if (window.viewportType === 'DESKTOP') {
        $.ajax({url: 'core/ajax/ajax_inmonpanier.php'}).done((data) => {
          $('#inmonpanier').html(data);
        });
      }
    });

    $(window).on('resize', function () {
      const old = window.viewportType;
      if (navigator.userAgent.includes("Mobile") || window.innerWidth <= 800) {
        if (window.innerWidth > 800) {
          window.viewportType = 'TABLET';
        } else {
          window.viewportType = 'MOBILE';
        }
      }else {
          window.viewportType = 'DESKTOP';
        } if (window.viewportType != old) {
          $('body').trigger('viewportChange', window.viewportType);
        }
    });

    // Switch info message every 3000ms
    setInterval(() => {
      $('#promomessage .slicktext>a:first').slideUp(200, 'swing', function () {
        $('#promomessage .slicktext>a:first').clone().show().appendTo('#promomessage .slicktext');
        $('#promomessage .slicktext>a:first').remove();
      });
    }, 3000)

    // EVENEMENTS DU COMPTE
    $('#moncompte .dropdown-btn').on('click', function (event) {
      if (true || window.viewportType === 'TABLET') {
        event.preventDefault();
        if($('#moncompte').hasClass('open')) {
          $('#moncompte').removeClass('open');
        }
        else {
          $('#moncompte').addClass('open');
          $('#btn-open-search').removeClass('open');
          $('#main-menu').removeClass('open')
          $('body').css('overflow-y', 'auto')
          $.ajax({url: 'core/ajax/ajax_inmoncompte.php'}).done((data) => {
            $('#inmoncompte').html(data);
            window.app.handleFolders($('#moncompte .foldered-content'))
            window.app.getAccountManagerInstance().then(am => am.handle($('#moncompte').find('form[name=inscription], form[name=deja_client]')))
            $('#password-forget a').on('click', function (event) {
              event.preventDefault()
              $('#inmoncompte').addClass('open')
              $('#moncompte').removeClass('open')
            });
            $('#account-link a').on('click', function () {
              $('#moncompte').removeClass('open')
            });
          });
        }
      }
    });

    if (window.viewportType === 'DESKTOP') {
      $('#moncompte').addClass('open');
      $.ajax({url: 'core/ajax/ajax_inmoncompte.php'}).done((data) => {
        $('#inmoncompte').html(data);
        window.app.handleFolders($('#moncompte .foldered-content'))
        window.app.getAccountManagerInstance().then(am => am.handle($('#moncompte').find('form[name=inscription], form[name=deja_client]')))
      });
    }

    var display = document.getElementById( "#popup-login" );
    $(".popup-moncompte").on('click', function (e) {
      if (display === true) {
        $("#moncompte").show();
      } else if (display === false) {
        $("#moncompte").hide();
      }
      e.stopPropagation();
    });

    $("body").click(function () {
      $("#moncompte").removeClass('open')
    });

    $("#inmoncompte").click(function (e) {
      e.stopPropagation();
    });

    // EVENEMENTS DU PANIER
    $('#monpanier .dropdown-btn').on('click', function (event) {
      if (window.viewportType === 'TABLET') {
        event.preventDefault();
        $('#monpanier').addClass('open');
        $.ajax({url: 'core/ajax/ajax_inmonpanier.php'}).done((data) => {
          $('#inmonpanier').html(data);
        });
      }
    });

    $('#monpanier .dropdown-btn').on('mouseenter', function () {
      $.get('core/ajax/ajax_inmonpanier.php', function (data) {
        $('#inmonpanier').html(data);
      });
      if (window.viewportType === 'DESKTOP') {
        $('#monpanier').addClass('open');
      }
    });

    $('#inmonpanier').on('mouseleave', function () {
      $('#monpanier').removeClass('open');
    });

    // EVENEMENTS DU PANIER
    $('#monpanier .dropdown-btn').on('click', function (event) {
      if (window.viewportType === 'TABLET') {
        event.preventDefault();
        $('#monpanier').addClass('open');
        $.ajax({url: 'core/ajax/ajax_inmonpanier.php'}).done((data) => {
          $('#inmonpanier').html(data);
        });
      }
    });

    // OUVRIR - FERMER MENU MOBILE
    $('#btn-open-menu').on('click', () => {
      if ($('#main-menu').hasClass('open'))
        this.closeMenu()
      else
        this.openMenu()
    });

    // EVENEMENTS BOUTON BARRE DE RECHERCHE
    $('#btn-open-search .dropdown-btn').on('click', () => {
      if (!$('#btn-open-search').hasClass('open')) {
        this.closeMenu()
        $('#moncompte').removeClass('open')
      } else {
        $('#btn-open-search').removeClass('open')
      }
    });

    // EFFACER LE TEXTE SAISIE
    $('#search-box .reset').on('click', () => {
      $('#search-box input').val('')
      $('#search-box input').trigger('keyup')
    });

    $(window).on('scroll', () => {
      this.checkScroll();
      if (window.viewportType === 'DESKTOP') {
        $('#moncompte').removeClass('open');
        $('#monpanier').removeClass('open');
      }
    });

    $(window).on('viewportChange', () => {
      this.checkScroll()
      this.closeMenu()
      $('#moncompte').removeClass('open');
      $('#monpanier').removeClass('open');
    });

  }

  openMenu() {
    $('#main-menu').addClass('open')
    $('#btn-open-search').removeClass('open')
    $('#moncompte').removeClass('open')
    $('body').css('overflow-y', 'hidden')
  }

  closeMenu() {
    $('#main-menu').removeClass('open')
    this.checkScroll()
    $('body').css('overflow-y', 'auto')
  }

  checkScroll() {
    if ($(window).scrollTop() == 0) {
      $('#btn-open-search').removeClass('open')
    } else {
      $('#btn-open-search').removeClass('open')
      $('#search-box input').val('')
      $('#search-box input').trigger('keyup')
      // $('#search-box input').trigger('blur') -- Fait disparaitre la div de recherche
    }
  }

  static handle($container, options) {
    return new ToolbarManager($container, options);
  }

}
