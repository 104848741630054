import EventManager from "../eventManager.js"
import StringUtils from "../utils/string.js"
import UAParser from 'ua-parser-js'

export default class LogsManager extends EventManager {
  constructor(settings) {
    super();
    this.settings = Object.assign(
      {},
      {
        postUrl: 'logger.php',
      }
    )
  }

  log(data) {
    // Wait page finish loading
    $(() => {
      data.date = (new Date()).toISOString()
      data.logType = data.logType === undefined ? 'log' : data.logType
      data.source = Object.assign({}, {
          referrer: document.referrer,
          href: document.location.href,
          breadcrumb: $('[itemtype=\'http://data-vocabulary.org/Breadcrumb\']').map((i, bc) => StringUtils.trim($(bc).text())).toArray(),
          h1: StringUtils.trim($('h1').first().text())
        },
        data.source)
      
      try {
        data.infos = Object.assign({}, UAParser(), data.infos)
      } catch(e) {
        
      }
      
      // Filter unwanted logs
      if (
        (data.infos && data.infos.userAgent 
          && /bingbot/gi.test(data.infos.userAgent))
        || (data.source && data.source.source 
          && /(connect\.facebook\.net|cevdecer\.com|titimop\.com)/gi.test(data.source.source))
        || (data.subject 
        && (data.subject === 'conversion' || data.subject === 'view_item_list'))
      ) {
        return
      }
      
      $.ajax({
        type: 'POST',
        url: this.settings.postUrl,
        data: data,
        async: true
      })
      .fail((jqXHR, textStatus, errorThrown) => {
      })
    })
   
  }
  
  error(data) {
    data.logType = 'error'
    this.log(data)
  }
  
  debug(data) {
    data.logType = 'debug'
    this.log(data)
  }
  
  warning(data) {
    data.logType = 'warning'
    this.log(data)
  }
  
  track(data) {
    data.logType = 'track'
    this.log(data)
  }
  
  gtag(data) {
    data.logType = 'gtag'
    if (data.length < 3) {
      return
    }
    let type = data[0]
    let subject = data[1]
    let content = data[2]
    
    if ((type === 'event')) {
      if (subject === 'click' && content.event_label === 'Ajout panier') {
        return
      }
      if (subject === 'view' && content.event_label.startsWith('Référence ')) {
        return
      }
      if (subject === 'page_view' && content.ecomm_prodid !== undefined) {
        return
      }
      if (content.send_to !== undefined) {
        delete content.send_to
      }
      this.track({
        subject, content
      })
    }
  }
}
 
