export class EventEmitter {
    constructor() {
        /**
         * @type {{event: string, callback: Function}[]}
         * @private
         */
        this._listeners = [];
    }

    emit(event, ...args) {
        this._listeners
            .filter(({event: eventName}) => eventName === event)
            .forEach(({callback}) => callback.apply(null, args));
    }

    on(event, callback) {
        if (typeof event === 'string' && typeof callback === 'function') {
            this._listeners.push({event, callback});
        }
    }

    off(event, callback = null) {
        console.log(event, callback);
        this._listeners = this._listeners.filter(listener => {
            if (callback === null) {
                console.log(listener.event === event);
                return listener.event !== event;
            }
            return !(listener.event === event && listener.callback === callback);
        });
    }
}
