import EventManager from "../eventManager.js"
import Config from "../config.js"

export default class MenuManager extends EventManager {

    constructor($target, options) {
        super();
        this.settings = $.extend({}, {}, options);
        this.container = $target;
        this.currentDropdown = null;

        const GoogleAnalyticsEvent = require('../tracking/googleAnalyticsEvent.js').default
        this.gaEvent = new GoogleAnalyticsEvent({category: 'Menu', transport: 'beacon'})

        $('#main-menu .dropdown').on('click', (event) => {
            if (window.viewportType !== 'DESKTOP') {
                const dropdown = $(event.delegateTarget);
                if (!$(event.delegateTarget).hasClass('open')) {
                    event.preventDefault();

                    // SCROLLING
                    const catalog = $('#main-menu .catalog').first();
                    let pos = catalog.scrollTop() + dropdown.offset().top - catalog.offset().top;
                    if (this.currentDropdown && this.currentDropdown.offset().top < dropdown.offset().top) {
                        pos -= this.currentDropdown.find('.dropdown-content').height();
                    }
                    catalog.animate({scrollTop: pos}, 400);

                    this.openDropdown(dropdown);
                } else {
                    if (window.viewportType === 'MOBILE') {
                        this.closeDropdown(dropdown);
                    }
                }
            }
            if($(event.target).is('a, a *')) {
                this.onMenuLinkClicked(event);
            }
        });

        $('#main-menu .dropdown').on('mouseenter', (event) => {
            if (window.viewportType === 'DESKTOP') {
                const dropdown = $(event.delegateTarget);
                this.openDropdown(dropdown);
            }
        });

        $('#main-menu .dropdown').on('mouseleave', (event) => {
            if (window.viewportType !== 'MOBILE') {
                const dropdown = $(event.delegateTarget);
                this.closeDropdown(dropdown);
            }
        });

    }

    openDropdown(dropdown) {
        // CLOSE OLD DROPDOWN
        this.closeDropdown(this.currentDropdown);

        // OPEN DROPDOWN
        dropdown.addClass('open');
        const dropdownContent = dropdown.find('.dropdown-content');
        dropdownContent.css('max-height', dropdownContent.prop('scrollHeight') + 'px');
        $(".icon-minus").hide();
        $(".icon-plus").show();
        dropdown.find(".icon-minus").show();
        dropdown.find(".icon-plus").hide();


        // UPDATE CURRENT DROPDON VARIABLE
        this.currentDropdown = dropdown;
    }

    closeDropdown(dropdown) {
        if (dropdown) {
            // CLOSE DROPDOWN
            dropdown.removeClass('open');
            dropdown.find('.dropdown-content').css('max-height', '0');
            $(".icon-plus").show();
            $(".icon-minus").hide();

            // SET CURRENT DROPDOWN TO NULL
            this.currentDropdown = null;
        }
    }

    onMenuLinkClicked(event) {
        if (event.isDefaultPrevented())
            return;

        let $link = $(event.target)
        let breadCrumb = [window.viewportType === 'DESKTOP' ? 'DESKTOP' : 'MOBILE', ...$link.parentsUntil($('#main-menu .catalog'))
                    .filter('[data-title]')
                    .map(function () {
                        return $(this).data('title')
                    })
                    .get()
                    .reverse()
        ]

        if (!$link.closest('div').is('[data-title]')) {
            breadCrumb.push($link.text().trim())
        }

        this.gaEvent.send({action: 'browse', label: breadCrumb.join(' > ')});
        this.on('follow_link', this, breadCrumb, ...arguments);
    }

    static handle($primaryMenu, options) {
        return new MenuManager($primaryMenu, options);
    }
}
