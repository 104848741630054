const toSnakeCase = (string) => {
  let unaccentedString = string.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  return string &&
      unaccentedString.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        .map(x => x.toLowerCase())
        .join('_')
}

const prepareDataForPaymentTunnel = (products) => {
  let items = []

  products.forEach(function (product) {
    let vendorName = product.product_vendor === 0 ? 'Bébé au Naturel' : product.product_vendors_name
    items.push({
      'product_vendors': product.product_vendors,
      'product_vendors_name': (vendorName && product.product_vendors_name) ? vendorName : 'N/A',
      'product_option_id': product.product_option_id,
      'item_name': product.item_name,
      'item_id': product.item_id,
      'price': parseFloat(product.price),
      'item_brand': product.item_brand,
      'item_category': product.item_category ? product.item_category : undefined,
      'item_category2': product.item_category2 ? product.item_category2 : undefined,
      'item_variant': product.item_variant,
      'quantity': parseFloat(product.quantity)
    })
  })

  return items
}

const prepareDataForPayment = (order) => {
  let items = []
  order.products.forEach(function (product) {
    items.push({
      'product_vendors': product.qt_vendors,
      'product_vendors_name': product.vendor_name ? product.vendor_name : 'N/A',
      'product_option_id': product.id_art_ref,
      'item_name': product.lib_article,
      'item_id': product.id_article,
      'price': parseFloat(product.px),
      'item_brand': product.marque,
      'item_category': product.item_category ? product.item_category : undefined,
      'item_category2': product.item_category2 ? product.item_category2 : undefined,
      'item_variant': product.lib_ref,
      'quantity': parseFloat(product.qt)
    })
  })

  return items
}

const prepareDataForViewItem = (product) => {
  let vendorName = product.product_vendor === 0 ? 'Bébé au Naturel' : product.product_vendors_name
  let item = {
    'product_vendors': product.product_vendors,
    'product_vendors_name': (vendorName && product.product_vendors_name) ? vendorName : 'N/A',
    'product_option_id': product.product_option_id,
    'item_name': product.item_name,
    'item_id': product.item_id,
    'price': parseFloat(product.price),
    'item_brand': product.item_brand,
    'item_category': product.item_category ? product.item_category : undefined,
    'item_category2': product.item_category2 ? product.item_category2 : undefined,
    'item_variant': product.item_variant
  }

  return item
}

/// ///// FOOTER LINKS CLICK ///// ///
const initFooterLinksClickEvent = () => {
  window.addEventListener('load', () => {
    document.querySelectorAll('#main_footer #info a').forEach((element) => {
      element.addEventListener('click', sendFooterLinkClickEvent)
    })
  })
}
const sendFooterLinkClickEvent = (element) => {
  window.dataLayer.push({
    'event': 'click_navigation',
    'navigation_type': 'navigation_footer',
    'footer_name': toSnakeCase(element.target.textContent)
  })
}

/// ///// MENU LINKS CLICK ///// ///
const initMenuLinksClickEvent = () => {
  window.addEventListener('load', () => {
    document.querySelectorAll('#main-menu a').forEach((element) => {
      element.addEventListener('click', sendMenuLinkClickEvent)
    })
  })
}
const sendMenuLinkClickEvent = (element) => {
  let level = getMenuLinkLevel(element.target)
  let hasLabel = menuLinkHasLabel(element, level)
  if (element.target.textContent) {
    window.dataLayer.push({
      'event': 'click_navigation',
      'navigation_type': 'navigation_menu',
      'menu_level': level,
      'menu_name': toSnakeCase(element.target.textContent),
      'menu_label': hasLabel ? 'yes' : 'no'
    })
  }
}
const getMenuLinkLevel = (link) => {
  if (link.hasAttribute('id_rubrique')) {
    return 2
  }
  if (link.classList.contains('dropdown-btn') || link.hasAttribute('aria-haspopup')) {
    return 1
  }
  return 3
}

const menuLinkHasLabel = (link, level) => {
  if (level === 3 && link.target.firstElementChild !== null && link.target.firstElementChild.classList.contains('badge')) {
    if (link.target.firstElementChild.classList.contains('badge')) {
      return true
    }
  }
  return false
}

/// ///// INIT DATA LAYER ///// ///
const initDatalayer = () => {
  let productId = 0
  if (document.querySelector('.module.produit [data-idart]')) {
    productId = document.querySelector('.module.produit [data-idart]').dataset.idart
  }
  $.get('core/ajax/ajax_google_analytics.php', {
    type: 'dataLayer_ready',
    url: window.location.toString(),
    produit: '0-' + productId + '-0'
  }, function (data) {
    window.dataLayer.push({
      'event': 'dataLayer_ready',
      'environment': data.environment,
      'page_type': data.page_type,
      'user_connected': data.user_connected,
      'user_status': data.user_status,
      'user_email': data.user_email,
    })
  }, 'json')
}

/// ///// PRODUCT ///// ///

/// VIEW ITEM ///

const initViewItemEvent = () => {
  window.addEventListener('viewItem', () => {
    let element = document.querySelector('[data-items]')
    if (element) {
      let dataItem = JSON.parse(element.getAttribute('data-items'))
      initViewItemDataLayer(dataItem)
    }
  })
}
const initViewItemDataLayer = (products) => {
  let items = []
  setTimeout(() => {
    products.forEach(function (product) {
      if (products.length === 1) {
        items.push(prepareDataForViewItem(product))
      } else if (products.length > 1) {
        let idArtRef = document.querySelector('.slick-slide.slick-current.slick-active img').getAttribute('src').split('-')[1].split('.')[0]
        if (product.product_option_id == idArtRef) {
          items.push(prepareDataForViewItem(product))
        }
      }
    })
    window.dataLayer.push({
      'event': 'view_item',
      'ecommerce': {
        items
      }
    })
  }, 500)
}

/// ///// CART ///// ///

/// ADD TO CART ///
const initAddToCartEvent = () => {
  window.addEventListener('addToCart', () => {
    let element = document.querySelector('[data-items]')
    if (element) {
      let quantity = document.querySelector('.quantity .option.selected').getAttribute('data-quantity')
      let dataItem = JSON.parse(element.getAttribute('data-items'))
      initAddToCartDataLayer(dataItem, quantity, false)
    }
  })

  window.addEventListener('listingAddToCart', () => {
    let element = document.querySelector('[data-item]')
    if (element) {
      let quantity = 1
      let dataItem = JSON.parse(element.getAttribute('data-item'))
      initAddToCartDataLayer(dataItem, quantity, true)
      element.removeAttribute('data-item')
    }
  })
}

const initAddToCartDataLayer = (products, quantity, isFromListing) => {
  let items = []
  let item = []
  products.forEach(function (product) {
    item = product
    if (!isFromListing) {
      let itemId = window.location.href.split(',')[3].split('-')[1]
      let idArtRef = window.location.href.split(',')[3].split('-')[2]
      let brand = document.querySelector('.merchants .option.selected').textContent
      let price = document.querySelector('.final .final').textContent.replace(/[^0-9.,]/g, '').replace(",", ".")

      if (item.product_option_id !== idArtRef) {
        item.item_id = itemId
        item.product_option_id = idArtRef
        item.item_brand = brand
        item.price = price
      }
    }
  })

  let vendorName = item.product_vendor === 0 ? 'Bébé au Naturel' : item.product_vendors_name
  items.push({
    'product_vendors': item.product_vendors,
    'product_vendors_name': (vendorName && item.product_vendors_name) ? vendorName : 'N/A',
    'product_option_id': item.product_option_id ? item.product_option_id : 'N/A',
    'item_name': item.item_name,
    'item_id': item.item_id,
    'price': parseFloat(item.price),
    'item_brand': item.item_brand,
    'item_category': item.item_category ? item.item_category : undefined,
    'item_category2': item.item_category2 ? item.item_category2 : undefined,
    'item_variant': item.item_variant,
    'quantity': parseFloat(quantity)
  })

  window.dataLayer.push({
    'event': 'add_to_cart',
    'ecommerce': {
      items
    }
  })
}

/// VIEW CART ///
const initViewCartEvent = () => {
  window.addEventListener('viewCart', () => {
    let element = document.querySelector('[data-orders]')

    if (element) {
      let dataOrders = JSON.parse(element.getAttribute('data-orders'))
      initViewCartDataLayer(dataOrders)
    }
  })
}

const initViewCartDataLayer = (orderValues) => {
  let items = prepareDataForPaymentTunnel(orderValues)

  window.dataLayer.push({
    'event': 'view_cart',
    'page_path': '/tunnel/recap',
    'ecommerce': {
      items
    }
  })
}

/// ///// CHECKOUT ///// ///

///  BEGIN CHECKOUT ///
const initBeginCheckoutEvent = () => {
  window.addEventListener('beginCheckout', () => {
    let element = document.querySelector('[data-orders]')
    if (element) {
      let dataOrders = JSON.parse(element.getAttribute('data-orders'))
      initBeginCheckoutDataLayer(dataOrders)
    }
  })
}

const initBeginCheckoutDataLayer = (orderValues) => {
  let items = prepareDataForPaymentTunnel(orderValues)
  window.dataLayer.push({
    'event': 'begin_checkout',
    'ecommerce': {
      items
    }
  })
}

/// SHIPPING INFO ///
const initAddShippingInfoEvent = () => {
  window.addEventListener('shippingSelected', () => {
    let element = document.querySelector('[data-orders]')
    if (element) {
      let dataOrders = JSON.parse(element.getAttribute('data-orders'))
      let dataShipping = element.getAttribute('data-shipping')
      initAddShippingInfoDataLayer(dataOrders, dataShipping, false)
    }
  })

  window.addEventListener('shippingModified', () => {
    let element = document.querySelector('[data-orders]')
    if (element) {
      let dataOrders = JSON.parse(element.getAttribute('data-orders'))
      let dataShipping = element.getAttribute('data-shipping')
      initAddShippingInfoDataLayer(dataOrders, dataShipping, true)
    }
  })
}

const initAddShippingInfoDataLayer = (orderValues, shipping, modification) => {
  let items = prepareDataForPaymentTunnel(orderValues)

  shipping = ['chronorelais', 'relais_colis', 'gls_relais', 'mondial_relay'].includes(shipping) ? 'point_relais' : 'domicile'

  window.dataLayer.push({
    'event': modification ? 'tunnel_shipping_modification' : 'add_shipping_info',
    'ecommerce': {
      'shipping_tier': shipping, // Retourne le mode de livraison séléctionné
      items
    }
  })
}

/// PAYMENT INFO ///
const initAddPaymentInfoEvent = () => {
  window.addEventListener('paymentSelected', () => {
    let element = document.querySelector('[data-orders]')
    if (element) {
      let dataOrders = JSON.parse(element.getAttribute('data-orders'))
      let dataPayment = element.getAttribute('data-payment')
      initAddPaymentInfoDataLayer(dataOrders, dataPayment)
    }
  })
}

const initAddPaymentInfoDataLayer = (orderValues, payment) => {
  let paymentMode = ''
  if (payment == 2) {
    paymentMode = 'CB'
  } else if (payment == 1) {
    paymentMode = 'cheque'
  } else if (payment == 3) {
    paymentMode = 'virement'
  }

  let items = prepareDataForPaymentTunnel(orderValues)

  window.dataLayer.push({
    'event': 'add_payment_info',
    'ecommerce': {
      'payment_type': paymentMode, // Retourne le mode de livraison séléctionné
      items
    }
  })
}

/// PURCHASE ///
const initPurchaseEvent = () => {
  window.addEventListener('load', () => {
    let element = document.querySelector('[data-orders]')
    if (element) {
      let data = JSON.parse(element.getAttribute('data-orders'))
      initPurchaseDataLayer(data)
    }
  })
}

const initPurchaseDataLayer = (orderValues) => {
  orderValues.forEach(function (order) {
    let items = prepareDataForPayment(order)

    window.dataLayer.push({
      'event': 'purchase_GA4',
      'payment_type': order.payment_type,
      'shipping_tier': order.shipping_tier,
      'ecommerce': {
        'currency': 'EUR',
        'value': parseFloat(order.totalHT), // Remonter le prix HT tel que payé par l'utilisateur
        'tax': parseFloat(order.montant_tax), // Remonter le prix de la taxe
        'shipping': parseFloat(order.totalPort), // Remonter le prix du mode de livraison
        'transaction_total': parseFloat(order.totalTTC), // Remonter le prix total
        'transaction_id': order.id_panier, // Retourne un identifiant de transaction unique
        'coupon': order.libelle_coupon, // Retourne le nom du coupon de reduction rattaché à la transaction
        'items': items
      }
    })
  })
}

/// ///// REGISTER ///// ///
const initVirtualPageRegisterFormEvent = () => {
  window.addEventListener('virtualPage', () => {
    let element = document.querySelector('[data-login]')
    if (element) {
      let data = element.getAttribute('data-login')
      initVirtualPageRegisterFormDataLayer(data)
    }
  })
}
const initVirtualPageRegisterFormDataLayer = (data) => {
  window.dataLayer.push({
    'event': 'virtual_page',
    'page_name': data
  })
}

/// ///// ADRESSE ///// ///
const initVirtualPageAdressFormEvent = () => {
  window.addEventListener('virtualPageAddress', () => {
    initVirtualPageAdressFormDataLayer()
  })
}
const initVirtualPageAdressFormDataLayer = () => {
  window.dataLayer.push({
    'event': 'virtual_page',
    'page_name': 'tunnel/adresse'
  })
}

/// ///// Form Send ///// ///
const initFormSendRegisterEvent = () => {
  window.addEventListener('formSend', () => {
    let header = document.querySelector('#header[data-header-send]')
    let register = document.querySelector('.module.login')

    if (header) {
      let data = JSON.parse(header.getAttribute('data-header-send'))
      initFormSendRegisterDataLayer(data)
    } else if (register) {
      let data = JSON.parse(register.getAttribute('data-register-send'))
      initFormSendRegisterDataLayer(data)
    }
  })

  window.addEventListener('formAddressSend', () => {
    let address = document.querySelector('.contenu.adresses')

    if (address) {
      let data = JSON.parse(address.getAttribute('data-address-send'))
      initFormSendRegisterDataLayer(data)
    }
  })
}
const initFormSendRegisterDataLayer = (data) => {
  window.dataLayer.push({
    'event': 'formulaire_send',
    'form_name': data.form_name,
    'form_status': data.form_status,
    'form_errors': data.form_errors
  })
  $.get('core/ajax/ajax_google_analytics.php', {
    type: 'delete_send_form_session'
  })
}

export default class Analytics {
  static init () {
    window.addEventListener('load', () => {
      if (!document.querySelector('.body-panier')) {
        document.cookie = 'cartViewed=false;'
      }
    })

    initDatalayer()
    initFooterLinksClickEvent()
    initMenuLinksClickEvent()
    initPurchaseEvent()
    initAddShippingInfoEvent()
    initAddPaymentInfoEvent()
    initViewItemEvent()
    initAddToCartEvent()
    initViewCartEvent()
    initBeginCheckoutEvent()
    initVirtualPageRegisterFormEvent()
    initVirtualPageAdressFormEvent()
    initFormSendRegisterEvent()
  }
}
