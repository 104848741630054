import './folder.scss';
import EventManager from "../eventManager.js";

export default class FoldersManager extends EventManager {

    constructor($folderedContent) {
        super();
        this.$folderedContent = $folderedContent;
        this.$folderedContent.find('> ul.folders > li').click(this.switch.bind(this));
        this.$folderedContent.find('> ul.folders > li').append('<div class="arrow"></div>');
        this.$folderedContent.find('> ul.folders > li:first-child').click();
    }

    switch(event) {
        this.$folderedContent.find('> ul.folders > li.selected, > div.folder-content').toggleClass('selected', false);
        $(event.delegateTarget).toggleClass('selected', true);
        let index = this.$folderedContent.find('> ul.folders > li').index(event.delegateTarget);
        this.$folderedContent.find('> div.folder-content').eq(index).toggleClass('selected', true);
    }

    static handle($folderedContent) {
        return new FoldersManager($folderedContent);
    }

}
