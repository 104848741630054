export default class GoogleAnalyticsEvent {

  constructor (options) {
    this.parameters = {
      hitType: 'event',
    }

    this.options = options
  }

  send (options) {
    let parameters = $.extend({}, this.options, options)

    // this.renameProperty(parameters, 'action')

    if (parameters.action === undefined) {
      throw {msg: 'no action defined', parameters: parameters}
    }

    if (parameters.send_to === undefined) {
      parameters.send_to = window.gtagIds.analytics
    }

    // move action parameters
    let action = parameters.action
    delete parameters.action

    // rename transport parameter
    if (parameters.transport !== undefined) {
      parameters.transport_type = parameters.transport
      delete parameters.transport
    }

    // rename nonInteraction parameter
    if (parameters.nonInteraction !== undefined) {
      parameters.non_interaction = parameters.nonInteraction
      delete parameters.nonInteraction
    }

    try {
      this.renameProperty(parameters, 'label')
    } catch (e) {
      // Nothing wrong here as these properties are optionnal
    }

    try {
      this.renameProperty(parameters, 'category')
    } catch (e) {
      // Nothing wrong here as these properties are optionnal
    }

    gtag(this.parameters.hitType, action,  parameters)
  }

  renameProperty (o, property) {
    let newProperty = 'event_' + property

    if (o[property] == undefined && o[newProperty] == undefined) {
      throw 'Object has no property ' + property + ', neither ' + newProperty
    }

    if (o[property] !== undefined) {
      Object.defineProperty(o, newProperty,
        Object.getOwnPropertyDescriptor(o, property))
      delete o[property]
    }
  }

}
