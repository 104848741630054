export default class ScrollHandler {

  constructor($container, options) {

    this.settings = $.extend({}, {
      scrollSteps: 1,
      color: 'white'
    }, options)


    this.$container = $container
    this.$wrapper = $('<div/>').addClass('scroll-wrapper')
    this.$container.wrap(this.$wrapper)
    this.$wrapper = this.$container.parent()
    var $leftHandle = $('<span class="left-handle"><i class="fa fa-angle-left" aria-hidden="true"></i></span>')
    var $rightHandle = $('<span class="right-handle"><i class="fa fa-angle-right" aria-hidden="true"></i></span>')

    this.$wrapper.append($leftHandle).append($rightHandle)

    this.$container.on('scroll', this.updateClasses.bind(this))
    $(window).on('resize', this.updateClasses.bind(this))

    this.$wrapper.children('.left-handle, .right-handle').click(event => {

      if ($(event.delegateTarget).is('.left-handle')) {

        this.previous()

      } else if ($(event.delegateTarget).is('.right-handle')) {

        this.next()

      }

    });

    import("./scroll.scss").then(() => {
        window.addEventListener("load", () => this.updateClasses())
    })

  }

  updateClasses(event) {
    this.$wrapper.toggleClass('scrollable-right', this.$container.scrollLeft() < this.maxScroll)
    this.$wrapper.toggleClass('scrollable-left', this.$container.scrollLeft() > 0)
  }

  get maxScroll() {
    return this.$container.prop('scrollWidth') - this.$container.innerWidth()
  }

  get currentStep()  {
    return this.settings.scrollSteps * (this.$container.scrollLeft() / this.$container.prop('scrollWidth'))
  }

  next() {
    if (this.$container.scrollLeft() < this.maxScroll) {
      this.goTo(Math.floor(this.currentStep + 1.01))
    }
  }

  previous() {
    if (this.$container.scrollLeft() > 0) {
      this.goTo(Math.ceil(this.currentStep) - 1)
    }
  }

  goTo(step) {
    // console.log(this.$container.innerWidth());
    this.$container.animate({ scrollLeft: step * this.$container.prop('scrollWidth') / this.settings.scrollSteps })
  }

}
