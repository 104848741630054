export class Accordion {
    /**
     * @param {Element} element
     */
    constructor(element) {
        this.container = element;
        /** @type {NodeListOf<HTMLButtonElement>} */
        this.buttons = this.container.querySelectorAll('[data-accordion-trigger]');
        /** @type {NodeListOf<Element>} */
        this.items = this.container.querySelectorAll('.tire-lait-accordion-item');
        this.openedItem = null;

        this.buttons.forEach(button => button.addEventListener('click', () => {
            const clickedItem = button.closest('.tire-lait-accordion-item');
            this.openedItem = clickedItem === this.openedItem ? null : clickedItem;
            this.render();
        }));

        this.render();
    }

    render() {
        this.items.forEach(item => {
            const isOpen = item === this.openedItem;
            item.classList.toggle('tire-lait-accordion-item-open', isOpen);
            item.querySelectorAll('[data-accordion-trigger]')
                .forEach(button => button.setAttribute('aria-expanded', JSON.stringify(isOpen)));
            const panelButton = item.querySelector('.tire-lait-accordion-panel-button');
            if (panelButton) {
                panelButton.textContent = isOpen ? 'Voir moins' : 'Voir plus';
            }
        });
        if (this.openedItem) {
            const header = this.openedItem.querySelector('.tire-lait-accordion-header');
            window.location.hash = `#${header.id}`;
        } else if (window.location.hash) {
            window.history.replaceState({}, '', window.location.href.replace(/#.+$/, ''));
        }
    }
}
