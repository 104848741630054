function getCallBackindex (callback, array) {
  let index = -1
  array.some(function(e, i) {
    if (Object.create(callback.prototype) instanceof e) {
      index = i
      return true
    }
  })
  return index
}

export default class EventManager {

  constructor () {
    this.listeners = {}
  }

  addListener(event, callback) {
    if (this.listeners[event] == undefined) {
      this.listeners[event] = []
    }

    this.listeners[event].push(callback)

    return this
  }

  removeListener(event, callback) {
    if (this.listeners[event] !== undefined) {
      if (callback !== undefined) {
        let index = getCallBackindex(callback, this.listeners[event])
        if (index !== -1) {
          delete this.listeners[event][index]
        }
      } else {
        delete this.listeners[event]
      }
    }

    return this;
  }

  on() {
    let [event, ...args] = [ ...arguments ]

    if (this.listeners[event] !== undefined) {
      this.listeners[event].map(callback => Promise.resolve(callback(this, ...args)))
    }

    return this
  }
}
