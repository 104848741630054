import {EventEmitter} from './event-emitter';

export class Popin extends EventEmitter {
    constructor(element) {
        super();

        this._popin = element;
        this._closeButton = this._popin.querySelector('.close-popin');
        this._isVisible = false;

        this._closeButton.addEventListener('click', () => this.close());
        this._popin.addEventListener('click', event => {
            if (event.target.matches('.pop-in-container')) {
                this.close();
            }
        });
        window.addEventListener('keydown', event => {
            if (event.key === 'Escape') {
                this.close();
            }
        });
        this._render();
    }

    open() {
        this.toggle(true);
    }

    close() {
        this.toggle(false);
    }

    toggle(visible = !this._isVisible) {
        this._isVisible = visible;
        this.emit(this._isVisible ? 'open' : 'close', this);
        this._render();
    }

    id() {
        return this._popin.id;
    }

    isVisible() {
        return this._isVisible;
    }

    _render() {
        this._popin.setAttribute('aria-hidden', this._isVisible ? 'false' : 'true');
        const toggleClass = () => this._popin.classList.toggle('pop-in-visible', this._isVisible);
        if (this._isVisible) {
            toggleClass();
        } else { // Wait for the animation to end to hide the popin
            this._popin.addEventListener('animationend', toggleClass, {once: true});
        }
    }
}
