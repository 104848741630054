import md5 from 'js-md5'

export default {
  ucFirst: function (str) {
    return str.charAt(0).toUpperCase() + str.slice(1)
  },

  md5: function (str) {
    return md5(str)
  },

  toLocaleString(str, options) {
    return typeof Intl == 'object' && typeof Intl.NumberFormat == 'function'
      ? str.toLocaleString('fr-FR', options)
      : `${str.toLocaleString('fr-FR')}${options.style === 'currency'?` ${options.currency === 'EUR'?` €`:''}`:''}`
  },
  
  trim: function (str) {
    return str.replace(/^[\n\s\uFEFF\xA0]+|[\n\s\uFEFF\xA0]+$/g, '')
  }
}
