import EventManager from "../eventManager.js"
import Loader from '../loader/loader.js'

export default class ProductManager extends EventManager {

  constructor() {
      super()
      this.managedProducts = {}
      window.addEventListener("popstate", this.onPopState.bind(this))

      let GoogleAnalyticsEvent = require('../tracking/googleAnalyticsEvent.js').default

      this.productFullGaEvent = new GoogleAnalyticsEvent({
        category: 'Fiche produit',
        transport: 'beacon'
      })

      this.productThumbnailGaEvent = new GoogleAnalyticsEvent({
        category: 'Rubrique',
        transport: 'beacon'
      })

      this.productRecommendationThumbnailGaEvent = new GoogleAnalyticsEvent({
        category: 'Recommendation',
        transport: 'beacon'
      })

      this.productFullAdwordsEvent = new GoogleAnalyticsEvent({
        send_to: window.gtagIds.adwords,
        ecomm_pagetype: 'product',
      })
  }

  handle($products) {
    let promises = []
    $products.each((i, p) => {
      if ($(p).is('.full')) {
        let loader = new Loader($(p))
        promises.push(new Promise((resolve, reject) => {
          import("./productFull.js").then(({default: ProductFull}) => {
            let product = new ProductFull($(p))
            let internalId = product.constructor.name + product.idArt
            this.managedProducts[internalId] = product
            product.load()
              .then(() => {
                if (product.idArtRef && product.idArtRef in product.data.references && product.data.references[product.idArtRef].state.forSell) {
                    product.currentReference = product.data.references[product.idArtRef]
                } else {
                  product.currentReference = product.data.references[product.getReferenceToLoadAtStart()]
                }
                app.getLogsManagerInstance().track({
                  subject: 'page_view',
                  content: {
                    pagetype: 'product',
                    items: [{
                      id: product.idArt,
                      name: product.name,
                      list_name: 'Fiche produit',
                      brand: product.brand,
                      category: product.category,
                      variant: product.variant,
                      list_position: 1,
                      quantity: product.quantity,
                      price: product.finalPrice
                    }]
                  }
                })
              })
              .then(() => {
                  if (product.configuredReference !== undefined) {
                    try {
                      history.replaceState({
                          internalId: internalId,
                          referenceIdArtRef: product.configuredReference.idArtRef
                        },
                        product.configuredReference.title,
                        document.location)
                    } catch (e) {
                      
                    }
                  }
                  product
                    .addListener('reference_change', this.onProductReferenceChange.bind(this, internalId))
                    .addListener('reference_change', this.trackReferenceView.bind(this))
                    .addListener('add_to_cart', this.addToCart.bind(this))
                  resolve({product: product})
                })
              .catch(error => {
                window.app.getNotificationManagerInstance().notifyUnexpectedError({reason: 'une erreur est survenue lors du chargement de la page. Essayez de recharger ou'})
                app.getLogsManagerInstance().error({
                  error: error,
                  message: 'Product load failed'
                })
                resolve({product: product, tid: tid})
              })
          })
        }).then(({product: product}) => {
          return product
        }))
      } else if ($(p).is('.thumbnail.recommendation')) {
        promises.push(import("./productRecommendationThumbnail.js").then(({default: ProductRecommendationThumbnail}) => {
          let product = new ProductRecommendationThumbnail($(p))
          let internalId = product.constructor.name + product.idArt
          this.managedProducts[internalId] = product
          product
            .addListener('follow_link', this.trackFollowLink.bind(this))
            .addListener('reference_change', this.trackReferenceView.bind(this))
            .addListener('add_to_cart', this.addToCart.bind(this))
          return product
        }))
      } else if ($(p).is('.thumbnail')) {
        promises.push(import("./productThumbnail.js").then(({default: ProductThumbnail}) => {
          let product = new ProductThumbnail($(p))
          let internalId = product.constructor.name + product.idArt
          this.managedProducts[internalId] = product
          product
            .addListener('follow_link', this.trackFollowLink.bind(this))
            .addListener('reference_change', this.trackReferenceView.bind(this))
            .addListener('add_to_cart', this.addToCart.bind(this))
          return product

        }))
      }
    })
    return Promise.all(promises)
      .then(products => this.trackReferenceView(products.filter(product => product.constructor.name !== 'ProductFull')))
  }

  onPopState({state}) {
    if (state !== null 
      && state.internalId !== undefined 
      && this.managedProducts[state.internalId] !== undefined
      && this.managedProducts[state.internalId].isLoaded()) {
      this.managedProducts[state.internalId]
        .removeListener('reference_change', this.onProductReferenceChange)

      this.managedProducts[state.internalId].currentReference = this.managedProducts[state.internalId].data.references[state.referenceIdArtRef]
      this.managedProducts[state.internalId]
        .addListener('reference_change', this.onProductReferenceChange.bind(this, state.internalId))
    }
  }

  onProductReferenceChange(internalId, product) {

    let link = product.configuredReference.link.replace(/^.+:\/\/[^/]+\//i, '')
    history.pushState({
        internalId: internalId,
        referenceIdArtRef: product.configuredReference.idArtRef
      },
      product.configuredReference.title,
      link)
  }

  trackFollowLink(product) {
    let googleTracker = (product.constructor.name === 'ProductThumbnail'
      ? this.productThumbnailGaEvent
      : this.productRecommendationThumbnailGaEvent)

    googleTracker.send({ action: 'click', label: product.recommendationCategory })
    googleTracker.send({
      action: 'select_content',
      content_type: "product",
      items: [{
        id: product.idArt,
        name: product.name,
        list_name: product.list_name,
        brand: product.brand,
        category: product.category,
        variant: product.variant,
        list_position: product.list_position,
        quantity: product.quantity,
        price: product.finalPrice
      }]
    })
  }

  trackReferenceView(products) {
    products = Array.isArray(products) ? products : [ products ]
    let fullProducts = products.filter(product => product.constructor.name === 'ProductFull')
    let thumbnailProducts = products.filter(product => product.constructor.name === 'ProductThumbnail')
    let recommendationsProducts = products.filter(product => product.constructor.name === 'ProductRecommendationThumbnail')

    let sliceAll = (arr, size) => {
      let step = 0, sliceArr = [], len = arr.length;
      while (step < len) {
        sliceArr.push(arr.slice(step, step += size));
      }
      return sliceArr
    }
    
    if (thumbnailProducts.length > 0) {
      let slicedThumbnailProducts = sliceAll(thumbnailProducts, 20)
      for (let thumbnailProductsChunk of slicedThumbnailProducts) {
        this.productThumbnailGaEvent.send({
          action: 'view_item_list',
          non_interaction: true,
          items: thumbnailProductsChunk.map(product => ({
            id: product.idArt,
            name: product.name,
            list_name: 'Rubrique',
            brand: product.brand,
            category: product.category,
            variant: product.variant,
            list_position: product.list_position,
            quantity: product.quantity,
            price: product.finalPrice
          }))
        })
      }
    }

    /*if (recommendationsProducts.length > 0) {
      let slicedRecommendationsProducts = sliceAll(recommendationsProducts, 20)
      for (let recommendationsProductsChunk of slicedRecommendationsProducts) {
        this.productRecommendationThumbnailGaEvent.send({
          action: 'view_item_list',
          non_interaction: true,
          items: recommendationsProductsChunk.map(product => ({
            id: product.idArt,
            name: product.name,
            list_name: 'Recommendations',
            brand: product.brand,
            category: product.category,
            variant: product.variant,
            list_position: product.list_position,
            quantity: product.quantity,
            price: product.finalPrice
          }))
        })
      }
    }*/

    if (fullProducts.length > 0) {
      this.productFullGaEvent.send({
        action: 'view_item',
        items: fullProducts.map(product => ({
          id: product.idArt,
          name: product.name,
          list_name: 'Fiche produit',
          brand: product.brand,
          category: product.category,
          variant: product.variant,
          list_position: 1,
          quantity: product.quantity,
          price: product.finalPrice
        }))
      })

      for ( let product of fullProducts ) {

        if (product.configuredReference.stock < 5) {
          this.productFullGaEvent.send({
            action: 'view',
            label: `Stock < 5`,
            non_interaction: true
          })
        }

        this.productFullGaEvent.send({
          action: 'view',
          label: `Référence ${product.configuredReference.idArt}/${product.configuredReference.idArtRef}`
        })

        this.productFullAdwordsEvent.send({
          action: 'page_view',
          ecomm_pagetype: 'product',
          ecomm_prodid: product.adwordId,
          ecomm_totalvalue: product.finalPrice,
          ecomm_category: product.category
        })
      }
    }
  }

  addToCart(product, event) {
    if (product.configuredReference === undefined) {
      window.app.getNotificationManagerInstance().info('', {
        title: 'Vous devez configurer votre produit avant de pouvoir l\'ajouter à votre panier',
        shape: 'banner',
        position: 'top',
        type: 'error'
      })
    } else {
      window.app.getBasketManagerInstance().then(basketManager => basketManager.addProduct(
        product.configuredReference.idArt,
        product.configuredReference.idArtRef,
        product.rubId,
        product.quantity,
        null,
        product.configuredReference.merchantId ).then(({idArtRef, quantity}) => {
          (product.constructor.name === 'ProductFull'
            ? this.productFullGaEvent
            : (product.constructor.name === 'ProductThumbnail'
              ? this.productThumbnailGaEvent
              : this.productRecommendationThumbnailGaEvent)).send({ action: 'click', label: 'Ajout panier' })

          product.quantityInCart = quantity
          if (product.data !== undefined) {
            product.data.references[idArtRef].quantityInCart = quantity
          }
        })
      )
    }
  }
}
