import "./popup.scss";
import EventManager from "../eventManager.js";
import Config from '../config.js'

export default class PopupManager extends EventManager {

  constructor(options) {
    super();

    this.settings = $.extend({}, {
      extraClassNames: []
    }, options);

    this.timeoutDescriptor = null;
    this.$container = $(`
      <div class="modalBoxWrap">
        <div class="wrapper">
          <div class="modalBox">
            <a class="closeModal"><i class="fa fa-times-circle"></i></a>
            <h4 class="title-wrapper"><span class="title"></span></h4>
            <div class="content"></div>
          </div>
        </div>
      </div>`);

    this.$container
      .appendTo($('body'))
      .hide();

    this.$container.find(".modalBox").click(function (event) {
        event.stopPropagation();
      });

  }

  static getInstance() {
    return new PopupManager();
  }

  isOpen() {
    return this.$container.is(':visible')
  }

  show(content, titre, options, postClose, preClose) {

    var settings = $.extend({}, {
      width: null,
      position: null,
      withOverlay: false,
      closingTimeout: null,
      modalClass: "",
      onContentLoad: () => {},
      extraClassNames: [],
      closingStyle: null
    }, options);

    let extraClassNames = $.unique($.merge(settings.extraClassNames, this.settings.extraClassNames))
    this.$container.attr('class', ['modalBoxWrap', ...extraClassNames].join(' '))

    this.$container.toggleClass("withOverlay", settings.withOverlay == true);

    this.$container.find(".title").html(titre);
    this.$container.find(".content").empty();
    this.$container.find(".content").append(content);

    this.on('contentLoaded', this.$container.find(".content"))
    settings.onContentLoad(this.$container.find(".content"))
    
    if (settings.width !== null) {
      this.$container.find(".wrapper .modalBox").css("width", settings.width);
    } else {
      this.$container.find(".wrapper .modalBox").css("width", '');
    }

    if (settings.position !== null && settings.position !== 'middle') {
      if (settings.position === 'bottom') {
        this.$container.css("top", "auto");
        this.$container.find(".wrapper").css("position", "relative");
      } else if (settings.position === 'top')  {
        this.$container.css("bottom", 'auto');
        this.$container.find(".wrapper").css("position", 'relative');
      }
    } else {
      this.$container.css("top", '');
      this.$container.css("bottom", '');
      this.$container.find(".wrapper").css("position", '');
    }

    this.$container.find(".wrapper .modalBox").attr('class', 'modalBox');
    this.$container.find(".wrapper .modalBox").toggleClass(settings.modalClass, true);

    clearTimeout(this.timeoutDescriptor);
    this.$container.show();
    this.$container.find('> .wrapper, .closeModal').off().click(this.close.bind(this, postClose, settings.closingStyle, preClose));

    if (settings.closingTimeout !== null && settings.closingTimeout > 0) {
      if (settings.shape === 'banner' && ! settings.closingStyle) {
        settings.closingStyle = 'fade'
      }
      this.timeoutDescriptor = setTimeout(this.close.bind(this, postClose, settings.closingStyle, preClose), settings.closingTimeout);
    }

    return this;
  }

  update(content) {
    this.$container.find(".content").html(content);
    this.on('contentLoaded', this.$container.find(".content"))
    return this;
  }

  confirm(message, options, onOk, onCancel) {

    var settings = $.extend({}, {
      title: "Confirmation",
      withOverlay: true,
      contentClass: "confirm",
      okLabel: 'Oui',
      cancelLabel: 'Non',
      width: '640px'
    }, options);

    var okButton = $('<button class="button ok"/>')
      .html(settings.okLabel)
      .click(this.close.bind(this, onOk));

    var cancelButton = $('<button class="button cancel"/>')
      .html(settings.cancelLabel)
      .click(this.close.bind(this, onCancel));

    var buttonToolbar = $('<div class="buttonToolbar"/>')
      .append(cancelButton)
      .append(okButton);

    var text = $('<p/>').html(message);

    var content = $('<div class="' + settings.contentClass + '"/>')
      .append(text)
      .append(buttonToolbar);

    return this.show(content, settings.title, settings, onCancel);
  }

  info(message, options, postClose, preClose) {

    var settings = $.extend({}, {
      title: null,
      withOverlay: options && options.shape && options.shape === 'banner' ? false : true,
      type: "info",
      okLabel: "Ok",
      shape: 'modal',
      extraClassNames: [],
      closingTimeout: null,
      position: null
    }, options);

    if (message=== null && settings.type === "error") {
      message = 'Quelquechose n\'a pas fonctionn&eacute;, essayez de recharger la page.';
    }

    if (settings.shape === 'banner') {
      if (settings.title === null) {
        settings.title = message
        message = null
      }
      settings.extraClassNames.push('banner')

      if (settings.closingTimeout === null) {
        settings.closingTimeout = 6000
      }

      if (settings.position === null) {
        settings.position = 'bottom'
      }

      if (! settings.width) {
        // settings.width = '100%'
      }
    }

    settings.extraClassNames.push(`type-${settings.type}`);

    if (settings.title === null) {
      if (settings.type === "error") {
        settings.title = "Une erreur est survenue...";
      } else if (settings.type === "info") {
        settings.title = "Information";
      }

    }

    let $content = null
    if (message !== null && message !== '') {
      let text = $('<p/>').html(message);
      $content = $('<div class="' + settings.contentClass + '"/>').append(text)
    }

    if (settings.shape !== 'banner' && $content) {
      var okButton = $('<button class="button ok"/>')
        .html(settings.okLabel)
        .click(this.close.bind(this, postClose, null, preClose));

      var buttonToolbar = $('<div class="buttonToolbar"/>').append(okButton);
      $content.append(buttonToolbar);
    }

    return this.show($content, settings.title, settings, postClose, preClose);

  }

  close(postClose, closingStyle, preClose) {

    let toDo = () => {
      if (typeof preClose === 'function' ) {
        preClose()
      }
      this.$container.find('.content').html('')
      if (typeof postClose === 'function' ) {
        postClose()
      }
    }

    if (closingStyle === 'fade') {
      this.$container.fadeOut(400, toDo)
    } else if (closingStyle !== 'keepOpen') {
      this.$container.hide()
      toDo()
    }

    this.on('close', this.$container.find(".content"))

    return this
  }

  video(videoName, title, options) {
    import('../video/video.js')
      .then(ClassDefinition => ClassDefinition.default)
      .then(VideoPlayer => {
        const player = new VideoPlayer(videoName, options);
        this.show(
                player.getVideoElement(),
                title,
                {
                    with: true,
                    modalClass: 'video',
                    width: '800px'
                },
                () => {
                    player.stop();
                    player.dispose();
                }
            );

        player.init();
      })

    return this;
  }
  
  notifyUnexpectedError(options) {
    return this.info('', Object.assign({}, {
      title: `Désolé, ${options && options.reason ? options.reason : 'une erreur inattendue est survenue,'} <a href="tel:${Config.contactPhoneNumber}"><i class="fa fa-phone" aria-hidden="true"></i> contactez notre équipe !</a>`,
      shape: 'banner',
      position: 'top',
      type: 'error'
    }, options))
  }
}
